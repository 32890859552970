<template>
  <div class="cs-filter-group" v-if="filter.nbAvailable">
    <div class="cs-filter-group-header" @click="filter.isOpen=!filter.isOpen">
      <span class="header-name">
        {{ filter.name }}
      </span>
      <span v-if="filter.nbChecked" class="header-nb">({{ filter.nbChecked }})</span>

<!--      <small v-if="filter.nbChecked" @click="clear">{{ 'clear' | trans }}</small>-->

      <span class="cs-arrows">
        <i v-if="!filter.isOpen" class="cs-icon-down-open"></i>
        <i v-if="filter.isOpen" class="cs-icon-up-open"></i>
      </span>
    </div>
    <div class="cs-params">
      <label  v-for="(param, index) in filter.params" v-show="index < visibleLimit || filter.isOpen" :class="[{ 'cs-dim':  param.nb == 0 }, 'cs-filter']" :for="'param' + filter.id + '_' + param.id">
        <input v-model="param.checked" @change="log" type="checkbox" :id="'param' + filter.id + '_' + param.id">
        <span>{{ param.name }} {{ param.id }}</span>
        <span>({{ param.nb }})</span>
      </label>

      <span class="show-all" v-if="filter.params.length > visibleLimit && visibleLimit > 0" @click="filter.isOpen=!filter.isOpen">
        <span v-if="!filter.isOpen">Show more ({{ filter.params.length - visibleLimit }})...</span>
        <span v-else>Show less...</span>
      </span>
    </div>
  </div>
</template>

<script>
module.exports = {
  data() {
    return {
      visibleLimit: 0,
    };
  },
  props: ['filter'],
  created() {

  },
  methods: {
    update() {
      this.filter.nbChecked = this.filter.params.filter((obj) => obj.checked === true).length;
    },
    clear() {
      this.filter.params.map(filterParam => {
        filterParam.checked = false;
        return filterParam;
      });
      this.update();
    },
    log() {
      console.log(this.filter);
      this.update();
    }
  }
};
</script>

<style lang="scss" scoped>
</style>