<template>
  <div class="miniature miniature-grid">
    <a href="#" class="images-container">
      <img class="miniature-image" :src="product.image" @alt="product.name">
      <div class="badges-container">
        <span v-if="product.isNew" class="badge badge-new">nowy</span>
        <span v-if="product.isSale" class="badge badge-sale">sale</span>
      </div>
    </a>
    <a href="#" class="name-container">
      <h3>{{ product.name }}</h3>
    </a>
    <div class="prices-container">
      <span class="price">{{ formatPrice(product.price) }}</span>
      <span v-if="product.reductionAmount" class="price-reduced">{{ formatPrice(product.priceReduced) }}</span>
    </div>
    <div class="actions-container">
      <a href="#" class="btn btn-success">
        <span class="cs-icon-basket"></span>
        See
      </a>
    </div>
  </div>
</template>

<script>
module.exports = {
  data() {
    return {
    };
  },
  props: ['product'],
  methods: {
    formatPrice(number) {
      const formatter = new Intl.NumberFormat('pl-PL', {
        style: 'currency',
        currency: 'PLN',
      });

      return formatter.format(number);
    }
  }
};
</script>

<style lang="scss" scoped>

</style>