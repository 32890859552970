import Vue from 'vue';
import App from "./components/SearchComponent.vue";
import LSPlaceholderType from './class/LSPlaceholderType.js'

const urlParams = new URLSearchParams(window.location.search);

Vue.filter('trans', function (value) {
    return value;
})

window.searchConf = {
    api: AP_API_ENDPOINT,
    container: '#search-container',
    opened: urlParams.get('_search'),
    typePhrases: [
        'Lorem ipsum dolor sit amet',
        'In hac habitasse platea dictumst',
        'Integer mattis gravida feugiat',
    ]
}

new Vue({
    el: window.searchConf.container,
    template: "<App/>",
    components: { App }
});

if (window.searchConf.typePhrases.length > 0) {
    setTimeout(function () {
        const type = new LSPlaceholderType(
            document.getElementsByClassName('ui-autocomplete-input')[0],
            window.searchConf.typePhrases
        );
        type.start();
    }, 1000);
}


console.log(window.searchConf);