<template>



  <div :class="{'cs-overlay' : true, 'cs-loading' : loading}">
    <div class="cs-content">
      <!-- Header -->
      <div class="cs-header">
        <div class="cs-search">
          <input type="text" v-model="query" class="form-control form-control-lg" placeholder="Enter search phrase..." @keyup="doStuff()">
          <span class="btn btn-info" @click="doStuff">
            <i class="cs-icon-cog"></i>
          </span>
        </div>
        <div class="cs-hints">
          <span class="cs-btn sm" v-for="(hint, i) in this.relatedSearches" @click="query=hint.query" v-if="i < 5">{{ hint.query }}</span>
        </div>
      </div>
      <!-- Columns -->
      <div class="cs-columns-container">
        <div v-bind:class="{ 'open': filters, 'cs-column-side': true }">

          <!-- Aside header -->
          <div class="cs-side-header">
            <span class="btn btn-info" @click="filters = !filters">
              <i class="cs-icon-filter"></i>
            </span>
          </div>


          <filter-group v-for="filter in filtersList" :key="filter.id" v-bind:filter="filter"></filter-group>


          <!-- Aside footer -->
          <div class="cs-side-footer">
            <span class="btn btn-info" @click="doStuff">
              Filter
            </span>
          </div>

        </div>
        <div class="cs-column-content">

          <!-- Sort row -->
          <div class="cs-sort-bar">

<!--            <span class="btn btn-info">({{ total }})</span>-->

            <span class="btn btn-info" @click="filters = !filters">
              <i class="cs-icon-filter"></i> Filtry ({{ filtersList.length }})
            </span>


            <span class="btn btn-info" @click="listStyle='grid'">
              <i class="cs-icon-th-large"></i>
            </span>
            <span class="btn btn-info" @click="listStyle='list'">
              <i class="cs-icon-th-list"></i>
            </span>

            <select v-model="order">
              <option value="name:asc">Trafność</option>
              <option value="name:asc">A-Z</option>
              <option value="name:desc">Z-A</option>
              <option value="price:asc">Cena - rosnąco</option>
              <option value="price:desc">Cena - malejąco</option>
            </select>

          </div>

<!--          <miniature-grid v-bind:product="this.recommendedProducts[1]"></miniature-grid>-->
<!--          <miniature-grid v-bind:product="this.recommendedProducts[2]"></miniature-grid>-->
<!--          <miniature-grid v-bind:product="this.recommendedProducts[3]"></miniature-grid>-->
<!--          <miniature-grid v-bind:product="this.recommendedProducts[1]"></miniature-grid>-->
<!--          <miniature-grid v-bind:product="this.recommendedProducts[2]"></miniature-grid>-->
<!--          <miniature-grid v-bind:product="this.recommendedProducts[3]"></miniature-grid>-->

          <miniature-grid v-if="listStyle === 'grid'" v-for="(product, index) in this.products" v-bind:product="product" v-bind:key="index"></miniature-grid>
          <miniature-list v-if="listStyle === 'list'" v-for="(product, index) in this.products" v-bind:product="product" v-bind:key="index"></miniature-list>

          <div v-if="hasMoreProducts" class="cs-load-more">
              <button @click="page++" class="btn">Następna strona</button>
          </div>

        </div>
      </div>
    </div>
  </div>



</template>

<script>
import axios from 'axios'
import MiniatureGrid from './MiniatureGrid.vue'
import MiniatureList from './MiniatureList.vue'
import FilterGroup from './FilterGroup.vue'
import LSConfig from '../class/LSConfig.js'
import qs from 'qs'

const config = new LSConfig();

export default {
  data() {
    return {
      active: false,
      loading: false,
      response: null,
      products: [],
      recommendedProducts: [],
      params: null,
      relatedSearches: null,
      paramsSelection: [],
      query: 'lakier',
      dev: false,
      paramsGroupShowMore: [],

      filtersList: [],

      order: config.get('order', 'name:desc'),
      orderBy: 'name',
      orderWay: 'asc',

      page: 0,
      nb: 10,

      listStyle: config.get('listStyle', 'grid'),

      // mobile
      filters: false,

        // visual
        hasMoreProducts: false,

      //
      tmpCheckedFilters: [],
      tmpCheckedFiltersIds: [],
      tmpOpenGroups: [],
      total: 0,
    }
  },
  components: {
    MiniatureGrid,
    MiniatureList,
    FilterGroup,
  },
  watch: {
    paramsSelection: function (val) {
      this.doStuff();
    },
    query: function (val) {
      this.doStuff();
    },
    orderBy: function (val) {
      this.doStuff();
    },
    orderWay: function (val) {
      this.doStuff();
    },
    listStyle: function (val) {
      config.set('listStyle', val);
    },
    order: function (val) {
      config.set('order', val);

      const words = val.split(':');
      this.orderBy = words[0];
      this.orderWay = words[1];
    },
      page: function (val) {
          this.doStuff();
      },
  },
  created() {
    this.doStuff();

    if (window.searchConf.opened) {
      this.open();
    }

    let that = this;
    document.addEventListener('click', this.onClickOutside);
    document.addEventListener('keyup', function (e) {
      if (e.keyCode === 27) {
        if (that.filters) {
          that.filters = false;
        } else if (that.active) {
          that.close();
        }
      }
    });
  },
  methods: {
    open() {
      document.body.classList.add('cs-open');
      this.active = true;
    },
    close() {
      document.body.classList.remove('cs-open');
      this.active = false;
    },
    onClickOutside(event) {
      if (event.target === document.getElementsByClassName('ui-autocomplete-input')[0]) {
        this.open();
      }
    },
    setCheckBoxFilteredValues(grid, id) {
      this.paramsSelection[grid] = id;
      this.doStuff();
    },
    nextPage() {
        this.page++;
    },
    doStuff() {


      let startTime = new Date().getTime();

      const data = {
        q: this.query,
        orderBy: this.orderBy,
        orderWay: this.orderWay,
        p: this.page,
        n: this.nb,
      };

      const searchParams = new URLSearchParams(data);

      this.tmpCheckedFilters = [];
      this.tmpCheckedFiltersIds = [];
      this.tmpOpenGroups = [];

      this.filtersList.forEach((filter) => {
        if (filter.isOpen) {
          this.tmpOpenGroups.push(filter.id);
        }
        filter.params.forEach((param) => {
          if (param.checked) {
            this.tmpCheckedFilters.push(filter.id + '-' + param.id);
            this.tmpCheckedFiltersIds.push(param.id);
          }
        })
      });

      console.log( this.tmpOpenGroups);

      this.loading = true;

      axios
          .get(window.searchConf.api + '?' + searchParams, {
            params: {
              params: this.tmpCheckedFilters,
            },
            paramsSerializer: function (params) {
              // return qs.stringify({ params: { '1': 1,'1': 12, '2':2 } }, { encode: false });

              //console.log(params.params)

              let str = ''
              params.params.forEach(child => {
                str += '&params[' + child.split('-')[0] + '][]=' + child.split('-')[1];
              });

              return str
            }
          })
          .then(response => {
            this.response = response.data
            this.products = response.data.products;
            this.recommendedProducts = response.data.recommended_products;
            this.params = response.data.params;
            this.relatedSearches = response.data.related_searches;

            this.lastRequestTime = (new Date().getTime() - startTime) / 1000;

            this.filtersList = response.data.params;
            this.total = response.data.total;

            // post-process
            this.hasMoreProducts = (this.page + 1) * this.nb < this.total;

            this.filtersList = this.filtersList.map(filter => {

              filter.params = filter.params.map(filterParam => {
                const filterParamTemplate = {
                  checked: this.tmpCheckedFiltersIds.includes(filterParam.id),
                };

                return {...filterParam, ...filterParamTemplate};
              });

              const nbChecked = filter.params.filter((param) => param.checked === true).length;
              const nbAvailable = filter.params.filter((param) => param.nb > 0).length;
              const filterTemplate = {
                nbChecked: nbChecked,
                nbAvailable: nbAvailable,
                isOpen: this.tmpOpenGroups.includes(filter.id),
              };


              setTimeout(() => {
                  this.loading = false;
              }, 500);

              return {...filter, ...filterTemplate};
            });

            // slide top
              document.getElementsByClassName('cs-column-content')[0].scrollTop = 0;

          });

    },
    highlight(name) {
      return name;
    }
  }
};
</script>

<style lang="scss" scoped>

.grid-2 {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;

  .miniature {
    width: calc(50% - 5px);
    overflow: hidden;
    margin-bottom: 30px;
  }
}

.grid-3 {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;

  .miniature {
    width: calc(33% - 5px);
    overflow: hidden;
    margin-bottom: 30px;
  }
}

.grid-4 {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;

  .miniature {
    width: calc(25% - 5px);
    overflow: hidden;
    margin-bottom: 30px;
  }
}

</style>