<template>
    <div class="miniature miniature-list">
        <a href="#" class="images-container">
            <img class="miniature-image" :src="product.image" @alt="product.name">
            <div class="badges-container">
                <span v-if="product.isNew" class="badge badge-new">nowy</span>
                <span v-if="product.isSale" class="badge badge-sale">sale</span>
            </div>
        </a>
        <a href="#" class="name-container">
            <h3>{{ product.name }}</h3>
        </a>
        <div class="prices-container">
            <span class="price">{{ product.price }}</span>
            <span v-if="product.reductionAmount" class="price-reduced">{{ formatPrice(product.priceReduced) }}</span>
        </div>
        <div class="actions-container">
            <a href="#" class="btn btn-success">
                <span class="cs-icon-basket"></span>
                <span class="cs-text">{{ 'Add to cart' | trans }}</span>
            </a>
        </div>
    </div>
</template>

<script>
    module.exports = {
        data() {
            return {
            };
        },
        props: ['product'],
    };
</script>

<style lang="scss" scoped>
</style>