var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.filter.nbAvailable
    ? _c("div", { staticClass: "cs-filter-group" }, [
        _c(
          "div",
          {
            staticClass: "cs-filter-group-header",
            on: {
              click: function($event) {
                _vm.filter.isOpen = !_vm.filter.isOpen
              }
            }
          },
          [
            _c("span", { staticClass: "header-name" }, [
              _vm._v("\n        " + _vm._s(_vm.filter.name) + "\n      ")
            ]),
            _vm._v(" "),
            _vm.filter.nbChecked
              ? _c("span", { staticClass: "header-nb" }, [
                  _vm._v("(" + _vm._s(_vm.filter.nbChecked) + ")")
                ])
              : _vm._e(),
            _vm._v(" "),
            _c("span", { staticClass: "cs-arrows" }, [
              !_vm.filter.isOpen
                ? _c("i", { staticClass: "cs-icon-down-open" })
                : _vm._e(),
              _vm._v(" "),
              _vm.filter.isOpen
                ? _c("i", { staticClass: "cs-icon-up-open" })
                : _vm._e()
            ])
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "cs-params" },
          [
            _vm._l(_vm.filter.params, function(param, index) {
              return _c(
                "label",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: index < _vm.visibleLimit || _vm.filter.isOpen,
                      expression: "index < visibleLimit || filter.isOpen"
                    }
                  ],
                  class: [{ "cs-dim": param.nb == 0 }, "cs-filter"],
                  attrs: { for: "param" + _vm.filter.id + "_" + param.id }
                },
                [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: param.checked,
                        expression: "param.checked"
                      }
                    ],
                    attrs: {
                      type: "checkbox",
                      id: "param" + _vm.filter.id + "_" + param.id
                    },
                    domProps: {
                      checked: Array.isArray(param.checked)
                        ? _vm._i(param.checked, null) > -1
                        : param.checked
                    },
                    on: {
                      change: [
                        function($event) {
                          var $$a = param.checked,
                            $$el = $event.target,
                            $$c = $$el.checked ? true : false
                          if (Array.isArray($$a)) {
                            var $$v = null,
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 &&
                                _vm.$set(param, "checked", $$a.concat([$$v]))
                            } else {
                              $$i > -1 &&
                                _vm.$set(
                                  param,
                                  "checked",
                                  $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                                )
                            }
                          } else {
                            _vm.$set(param, "checked", $$c)
                          }
                        },
                        _vm.log
                      ]
                    }
                  }),
                  _vm._v(" "),
                  _c("span", [
                    _vm._v(_vm._s(param.name) + " " + _vm._s(param.id))
                  ]),
                  _vm._v(" "),
                  _c("span", [_vm._v("(" + _vm._s(param.nb) + ")")])
                ]
              )
            }),
            _vm._v(" "),
            _vm.filter.params.length > _vm.visibleLimit && _vm.visibleLimit > 0
              ? _c(
                  "span",
                  {
                    staticClass: "show-all",
                    on: {
                      click: function($event) {
                        _vm.filter.isOpen = !_vm.filter.isOpen
                      }
                    }
                  },
                  [
                    !_vm.filter.isOpen
                      ? _c("span", [
                          _vm._v(
                            "Show more (" +
                              _vm._s(
                                _vm.filter.params.length - _vm.visibleLimit
                              ) +
                              ")..."
                          )
                        ])
                      : _c("span", [_vm._v("Show less...")])
                  ]
                )
              : _vm._e()
          ],
          2
        )
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }