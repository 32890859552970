export default class LSConfig {
    constructor(key) {
        this.key = key !== undefined ? key : 'csSearchConfig';

        const retrievedObject = localStorage.getItem(this.key);
        this.config = retrievedObject ? JSON.parse(retrievedObject) : {};
    }

    get(key, defaultValue) {
        return this.config[key] !== undefined ? this.config[key] : defaultValue;
    }

    set(key, value) {
        this.config[key] = value;
        this.store();
    }

    store() {
        localStorage.setItem(this.key, JSON.stringify(this.config));
    }
}
