export default class LSPlaceholderType {

    constructor(object, phrasesList, typeSpeed, betweenPhraseDelay) {
        this.object = object;
        this.phrasesList = phrasesList;
        this.typeSpeed = typeof typeSpeed === 'undefined' ? 50 : typeSpeed;
        this.betweenPhraseDelay = typeof typeSpeed === 'undefined' ? 1000 : betweenPhraseDelay;
    }

    start() {
        this.write();
    }

    write() {

        var targetEl = this.object;
        var phrasesList = this.phrasesList;
        var isAdding = true;
        var index = 0;
        var phraseIndex = 0;
        var typeSpeed = this.typeSpeed;
        var betweenPhraseDelay = this.betweenPhraseDelay;

        function typeText() {
            setTimeout(function () {

                targetEl.placeholder = phrasesList[phraseIndex].slice(0, index);

                if (isAdding) {
                    // adding text
                    if (index > phrasesList[phraseIndex].length) {
                        // no more text to add
                        isAdding = false

                        // wait before playing again
                        setTimeout( function () {
                            typeText()
                        }, betweenPhraseDelay)
                        return
                    } else {
                        index++
                    }
                } else {
                    // removing text
                    if (index === 0) {
                        // no more text to remove
                        isAdding = true

                        // next phrase
                        if (phraseIndex+1 >= phrasesList.length) {
                            phraseIndex = 0;
                        } else {
                            phraseIndex++;
                        }

                    } else {
                        index--
                    }
                }
                typeText();
            }, typeSpeed)
        }

        // start
        typeText();
    }
}
