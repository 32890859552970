var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: { "cs-overlay": true, "cs-loading": _vm.loading } },
    [
      _c("div", { staticClass: "cs-content" }, [
        _c("div", { staticClass: "cs-header" }, [
          _c("div", { staticClass: "cs-search" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.query,
                  expression: "query"
                }
              ],
              staticClass: "form-control form-control-lg",
              attrs: { type: "text", placeholder: "Enter search phrase..." },
              domProps: { value: _vm.query },
              on: {
                keyup: function($event) {
                  return _vm.doStuff()
                },
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.query = $event.target.value
                }
              }
            }),
            _vm._v(" "),
            _c(
              "span",
              { staticClass: "btn btn-info", on: { click: _vm.doStuff } },
              [_c("i", { staticClass: "cs-icon-cog" })]
            )
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "cs-hints" },
            _vm._l(this.relatedSearches, function(hint, i) {
              return i < 5
                ? _c(
                    "span",
                    {
                      staticClass: "cs-btn sm",
                      on: {
                        click: function($event) {
                          _vm.query = hint.query
                        }
                      }
                    },
                    [_vm._v(_vm._s(hint.query))]
                  )
                : _vm._e()
            }),
            0
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "cs-columns-container" }, [
          _c(
            "div",
            { class: { open: _vm.filters, "cs-column-side": true } },
            [
              _c("div", { staticClass: "cs-side-header" }, [
                _c(
                  "span",
                  {
                    staticClass: "btn btn-info",
                    on: {
                      click: function($event) {
                        _vm.filters = !_vm.filters
                      }
                    }
                  },
                  [_c("i", { staticClass: "cs-icon-filter" })]
                )
              ]),
              _vm._v(" "),
              _vm._l(_vm.filtersList, function(filter) {
                return _c("filter-group", {
                  key: filter.id,
                  attrs: { filter: filter }
                })
              }),
              _vm._v(" "),
              _c("div", { staticClass: "cs-side-footer" }, [
                _c(
                  "span",
                  { staticClass: "btn btn-info", on: { click: _vm.doStuff } },
                  [_vm._v("\n              Filter\n            ")]
                )
              ])
            ],
            2
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "cs-column-content" },
            [
              _c("div", { staticClass: "cs-sort-bar" }, [
                _c(
                  "span",
                  {
                    staticClass: "btn btn-info",
                    on: {
                      click: function($event) {
                        _vm.filters = !_vm.filters
                      }
                    }
                  },
                  [
                    _c("i", { staticClass: "cs-icon-filter" }),
                    _vm._v(
                      " Filtry (" +
                        _vm._s(_vm.filtersList.length) +
                        ")\n            "
                    )
                  ]
                ),
                _vm._v(" "),
                _c(
                  "span",
                  {
                    staticClass: "btn btn-info",
                    on: {
                      click: function($event) {
                        _vm.listStyle = "grid"
                      }
                    }
                  },
                  [_c("i", { staticClass: "cs-icon-th-large" })]
                ),
                _vm._v(" "),
                _c(
                  "span",
                  {
                    staticClass: "btn btn-info",
                    on: {
                      click: function($event) {
                        _vm.listStyle = "list"
                      }
                    }
                  },
                  [_c("i", { staticClass: "cs-icon-th-list" })]
                ),
                _vm._v(" "),
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.order,
                        expression: "order"
                      }
                    ],
                    on: {
                      change: function($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function(o) {
                            return o.selected
                          })
                          .map(function(o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.order = $event.target.multiple
                          ? $$selectedVal
                          : $$selectedVal[0]
                      }
                    }
                  },
                  [
                    _c("option", { attrs: { value: "name:asc" } }, [
                      _vm._v("Trafność")
                    ]),
                    _vm._v(" "),
                    _c("option", { attrs: { value: "name:asc" } }, [
                      _vm._v("A-Z")
                    ]),
                    _vm._v(" "),
                    _c("option", { attrs: { value: "name:desc" } }, [
                      _vm._v("Z-A")
                    ]),
                    _vm._v(" "),
                    _c("option", { attrs: { value: "price:asc" } }, [
                      _vm._v("Cena - rosnąco")
                    ]),
                    _vm._v(" "),
                    _c("option", { attrs: { value: "price:desc" } }, [
                      _vm._v("Cena - malejąco")
                    ])
                  ]
                )
              ]),
              _vm._v(" "),
              _vm._l(this.products, function(product, index) {
                return _vm.listStyle === "grid"
                  ? _c("miniature-grid", {
                      key: index,
                      attrs: { product: product }
                    })
                  : _vm._e()
              }),
              _vm._v(" "),
              _vm._l(this.products, function(product, index) {
                return _vm.listStyle === "list"
                  ? _c("miniature-list", {
                      key: index,
                      attrs: { product: product }
                    })
                  : _vm._e()
              }),
              _vm._v(" "),
              _vm.hasMoreProducts
                ? _c("div", { staticClass: "cs-load-more" }, [
                    _c(
                      "button",
                      {
                        staticClass: "btn",
                        on: {
                          click: function($event) {
                            _vm.page++
                          }
                        }
                      },
                      [_vm._v("Następna strona")]
                    )
                  ])
                : _vm._e()
            ],
            2
          )
        ])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }