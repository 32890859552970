var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "miniature miniature-grid" }, [
    _c("a", { staticClass: "images-container", attrs: { href: "#" } }, [
      _c("img", {
        staticClass: "miniature-image",
        attrs: { src: _vm.product.image },
        on: { alt: _vm.product.name }
      }),
      _vm._v(" "),
      _c("div", { staticClass: "badges-container" }, [
        _vm.product.isNew
          ? _c("span", { staticClass: "badge badge-new" }, [_vm._v("nowy")])
          : _vm._e(),
        _vm._v(" "),
        _vm.product.isSale
          ? _c("span", { staticClass: "badge badge-sale" }, [_vm._v("sale")])
          : _vm._e()
      ])
    ]),
    _vm._v(" "),
    _c("a", { staticClass: "name-container", attrs: { href: "#" } }, [
      _c("h3", [_vm._v(_vm._s(_vm.product.name))])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "prices-container" }, [
      _c("span", { staticClass: "price" }, [
        _vm._v(_vm._s(_vm.formatPrice(_vm.product.price)))
      ]),
      _vm._v(" "),
      _vm.product.reductionAmount
        ? _c("span", { staticClass: "price-reduced" }, [
            _vm._v(_vm._s(_vm.formatPrice(_vm.product.priceReduced)))
          ])
        : _vm._e()
    ]),
    _vm._v(" "),
    _vm._m(0)
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "actions-container" }, [
      _c("a", { staticClass: "btn btn-success", attrs: { href: "#" } }, [
        _c("span", { staticClass: "cs-icon-basket" }),
        _vm._v("\n      See\n    ")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }