import { render, staticRenderFns } from "./FilterGroup.vue?vue&type=template&id=0ddf876a&scoped=true&"
import script from "./FilterGroup.vue?vue&type=script&lang=js&"
export * from "./FilterGroup.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0ddf876a",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/www/prestashop/ps-wyszukiwarka-fo/modules/search/_dev/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('0ddf876a')) {
      api.createRecord('0ddf876a', component.options)
    } else {
      api.reload('0ddf876a', component.options)
    }
    module.hot.accept("./FilterGroup.vue?vue&type=template&id=0ddf876a&scoped=true&", function () {
      api.rerender('0ddf876a', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/js/components/FilterGroup.vue"
export default component.exports